import * as actionType from "../actionTypes";
import Axios from "../../../services/NetworkService";
import { sensorTypes } from "../../../constants/sensorTypes";
import store from "../../store/store";
import moment from "moment";

export const mixChartPending = () => {
  return {
    type: actionType.ANALYTICS_MIXCHART_PENDING,
    payload: "loading...",
  };
};

export const mixChartSuccess = (data) => {
  return {
    type: actionType.ANALYTICS_MIXCHART_SUCCESS,
    payload: data,
  };
};

export const mixChartFail = (error) => {
  return {
    type: actionType.ANALYTICS_MIXCHART_FAIL,
    error: error,
  };
};

export const mixChartLoaded = () => {
  return {
    type: actionType.ANALYTICS_MIXCHART_LOADED,
  };
};

export const mixChartReset = () => {
  return {
    type: actionType.ANALYTICS_MIXCHART_RESET,
  };
};

const fetchSensorsData = async (
  dispatch,
  start,
  end,
  sensors,
  todayStart,
  isToday
) => {
  try {
    // Ensure start and end are Date objects
    const startDate = start instanceof Date ? start : new Date(start);
    const endDate = end instanceof Date ? end : new Date(end);
    const todayStartDate =
      todayStart instanceof Date ? todayStart : new Date(todayStart);

    // Check if the dates are valid
    if (
      isNaN(startDate.getTime()) ||
      isNaN(endDate.getTime()) ||
      isNaN(todayStartDate.getTime())
    ) {
      throw new Error("Invalid date range");
    }

    // const startTime = startDate.getTime();
    // const endTime = endDate.getTime();

    const smartRiverData = [];
    const smartRiverIds = [];
    const hdcData = [];
    const hdcIds = [];
    const gflData = [];
    const gflIds = [];

    for (let i = 0; i < sensors.length; i++) {
      if (store.getState().mixChart.isTerminated) {
        dispatch(mixChartReset());
        break;
      }
      const sensorName = sensors[i].label;
      const sensorType = sensors[i].sensorType;
      const sensorId = sensors[i].value;
      const soleCorrectionValue =
        sensors[i].soleCorrectionValue ?? sensors[i].soleCorrectionValue;

      if (sensorType === sensorTypes.SMARTRIVER) {
        smartRiverData.push({
          soleCorrectionValue,
          sensorName,
          sensorId,
          values: [],
        });
        smartRiverIds.push(sensorId);
      } else if (sensorType === sensorTypes.HDC) {
        hdcData.push({
          sensorName,
          sensorId,
          values: [],
        });
        hdcIds.push(sensorId);
      } else if (sensorType === sensorTypes.GFL) {
        gflData.push({
          sensorName,
          sensorId,
          values: [],
        });
        gflIds.push(sensorId);
      }
    }

    // const duration = (endTime - startTime) / 1000 / 60;

    if (smartRiverIds.length > 0) {
      const smartriverStartDateVal = isToday ? todayStartDate : start;

      const result = await Axios.post("/smartriverdata", {
        start: smartriverStartDateVal,
        end: endDate.toISOString(),
        deviceIds: smartRiverIds,
        resolution: 0,
        filter: true,
      });
      smartRiverData.forEach(
        (d) => (d.values = result.data.data[d.sensorId] || [])
      );
      await dispatch(mixChartSuccess({ smartRiverData, hdcData, gflData }));
    }

    if (hdcIds.length > 0) {
      const hdcStartDateVal = isToday ? todayStartDate : start;

      const result = await Axios.post("/hdcdata", {
        start: moment(hdcStartDateVal).subtract(1, "hour").toISOString(),
        end,
        deviceIds: hdcIds,
        resolution: 60,
      });

      hdcData.forEach((d) => {
        d.values = result.data.data[d.sensorId] || [];
      });

      await dispatch(mixChartSuccess({ smartRiverData, hdcData, gflData }));
    }

    if (gflIds.length > 0) {
      const gflStartDateVal = isToday ? todayStartDate : start;

      const result = await Axios.post("/gfldata", {
        start: gflStartDateVal,
        end,
        resolution: 0,
        deviceIds: gflIds,
      });

      gflData.forEach((d) => (d.values = result.data.data[d.sensorId] || []));
      await dispatch(mixChartSuccess({ smartRiverData, hdcData, gflData }));
    }

    dispatch(mixChartLoaded());
  } catch (error) {
    console.error("Error fetching sensor data:", error);
    dispatch(mixChartFail(error.message));
  }
};

// Line 165: if no hdcStart get set a default value
export const mixChartAction = (
  start,
  end,
  sensors,
  todayStart = new Date(),
  isToday = false
) => {
  return async function (dispatch) {
    try {
      dispatch(mixChartPending());
      await fetchSensorsData(
        dispatch,
        start,
        end,
        sensors,
        todayStart,
        isToday
      );
    } catch (error) {
      console.error("Error in mixChartAction:", error);
      return dispatch(mixChartFail(error));
    }
  };
};
