import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import AppRouter from "./components/Router/AppRouter";
import useAuthCheck from "./hooks/useAuthCheck";
import { Toaster } from "sonner";
import "./fonts.css";
import Spinner from "./components/UI/Spinner/Spinner";
import AddToHomeScreen from "./components/UI/Banner/AddToHomeScreen";
import InstallPrompt from "./components/UI/Banner/InstallPrompt";
import useMobileView from "./hooks/useMobileView";
// import { runOneSignal } from "./services/OneSignal";

const App = () => {
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const authChecked = useAuthCheck();
  const isMobileView = useMobileView();

  // Log the REACT_APP_BASE_URL from the environment variables
  // console.log("REACT_APP_BASE_URL:", process.env.REACT_APP_BASE_URL);

  // useEffect(() => {
  //   console.log("REACT_APP_BASE_URL:", process.env.REACT_APP_BASE_URL);
  // }, []);

  const authenticationLoader = (
    <div className="absolute z-10 flex flex-col items-center justify-center left-1/2 top-1/2">
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    </div>
  );

  if (!authChecked) return authenticationLoader;

  return (
    <div
      className={`${isThemeDark ? "dark" : ""} ${
        isThemeDark ? "bg-spekterDarkThree" : "bg-gray-50"
      } min-h-screen`}
    >
      {/* <InstallPrompt />
      <AddToHomeScreen /> */}
      <Router>
        <AppRouter />
      </Router>

      <Toaster
        richColors
        closeButton
        position={isMobileView ? "top-center" : "top-right"}
      />
    </div>
  );
};

export default App;
